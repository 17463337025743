@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Lobster&family=Ubuntu:ital@1&display=swap);
body{
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
}

#root{
    width: 80%;  /*Fixer une largeur au site */
    margin: auto; /*Center si la largeur est fixée */   
}

.layout{
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-areas:
                          "nav nav"
                          "leftSide titreCV"
                          "leftSide rightSide"
                          "footer footer";
  grid-gap: 5px;
  gap: 5px;

}

div.titreCV{
  grid-area: titreCV;
  background-color:#11304d;
  color: white;
  border-radius: 10px;
  position: -webkit-sticky;
  position: sticky;  
  top: 0 ; 
  z-index: 1;
}

div#blocGauche{
  grid-area: leftSide;
}

div.container{
  grid-area: rightSide;
}

img.logoEntreprise{
  width:150px;
  height: 40px;
  background-size: cover; 
  border-radius: 5px;
}
nav {
  grid-area: nav;
  background-color:#11304d;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

nav a, .nav-link {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
a.active:not(.navbar-brand){
  background-color:  #325e86;
  color: white;
} 
ul, p {
  margin: 0;
}
h2{
  font-family: 'Lobster', cursive;
  text-align: center;
}
p, li {
  font-family: 'Arsenal', sans-serif;
}
ul{
  list-style: none;
}
div.card{
  margin-top: 5px;
}
div.card-body:hover{
  background-color: #11304d;
  color: white;
  transition: 0.5s;
}
p.textStyle{
  text-decoration: underline;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;
  letter-spacing: 2px;
}
span.env{
  font-weight: bold;
  margin-right: 5px;
}
.styletext{
  text-decoration: underline;
  font-weight: bold;
}

/****** Objectifs *********/
section#objectifs{
	padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin: 5px;
    box-shadow: 2px 5px 5px gray;
}
.titreObjectif{
	text-decoration: underline;
  text-align: left;
}
.text_souligne{
	text-decoration: underline;
}
.text_gras{
	font-weight: bold;
}

/****** Mission *********/
div.enteteMission{
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 2px 5px 5px gray;
  display: flex;
  justify-content: center;
}
div.infoClient{
  padding-left: 15px;
}
.styletext{
  text-decoration: underline;
  font-weight: bold;
}
img.logo{
 width: 160px;
 height: 60px;
 background-size: cover; 
 border-radius: 10px;
}
/***** LeftSidebar ******/
section#image{ /*centrer #image contenant le span.photo */
  text-align: center;
  margin-top: 5px;
}
span.photo{
  /*background-image: url('../images/joseph_400x400.jpg');*/ 
  background-size: cover; /* Couvre au mieux l'espace qui t'es alloué */
  width:160px;
  height:160px;
  margin: auto;
  display:inline-block;
  border-radius: 50%; /*Arrondi le span */
  border: 3px solid white;
}


#blocGauche ul, p{
  margin: 0;
  padding: 0;
}
#blocGauche section ul, #blocGauche section:not(#details_personnels) p{
  text-align: center;
}

#blocGauche section:hover{
  background-color: white;
}
span.photo:hover{ 
  border: 3px solid rgb(3, 244, 3);
}
div#scrollVertical section {
  padding-left: 10px;
}
div#scrollVertical{
  height: 450px;
  overflow: auto;
}
div#strickyVertical{
  position: -webkit-sticky;
  position: sticky; /*La propriété sticky a besoin d'un point pour bloquer */
  top: 0 ; /* Définir un point de blocage */
}
#blocGauche  h1, h2, h3 {
  text-align: center;
}
#blocGauche ul li{
  list-style: none;
}
#blocGauche img{
  vertical-align: middle;
}

div#strickyVertical{
  position: -webkit-sticky;
  position: sticky; /*La propriété sticky a besoin d'un point pour bloquer */
top: 0 ; /* Définir un point de blocage */
}

#blocGauche{
  background-color: #bdc3c7; 
  grid-area: blocGauche;
  border-radius: 10px;
}
#blocGauche section{
  margin: 2px;
  border-radius: 10px;
}

#blocGauche h3{
  font-family: 'Lobster', cursive;
}

section#details_personnels{
display: flex;
justify-content: center;
}

/*****End LeftSidebar ****/

/************* FOOTER ***********/
footer{ 
  background-color: #11304d;
  grid-area: footer;
  text-align: center;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
footer span{
  font-style: italic;
}
footer p {
  margin-bottom: 7px;
}

/*Résau sociaux */
#reseau{
  display: flex; /*La zone nav, on en fait un conteneur */
  justify-content: center ;
}
.box a{
  color: #f5a31f;
  text-decoration: none;
}
.box {
  width: 25%;
  height: 70px;
  position:relative; /* width,  height et pos relative nécessaire pour la superpositon */
  display: flex;
  justify-content: center;
  align-items: center;
}
.box h4, .box span{
  position:absolute; /* pos absolute, indispensable pour faire de la superposition */
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0; /* cacher */
}

.box h4{
  font-size: 15px;
  text-transform: uppercase;
  top: 5px;
}
.box span{
  color: white;
  font-size: 12px;
  bottom: 5px;
}

.box i{
  font-size: 22px;
  transition: 0.3s;
}
.box:hover i{
  -webkit-transform: scale(4);
          transform: scale(4); /* 4* fois plus gros */
  opacity: 0;
  transition: 0.3s;
}

.box:hover h4{
  opacity: 1;
  top: 15px;
  transition: 0.5s;
}
.box:hover span{
  opacity: 1;
  bottom: 5px;
  transition: 0.5s;
}

/*********END FOOTER ************/

@media screen and (max-width: 800px) {
  body{
    font-size: 14px;
  }
  #root{
    width: 100%;  
    margin: auto; 
  }
  .layout{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
                            "nav"
                            "titreCV"
                            "rightSide"
                            "leftSide"
                            "footer";
    grid-gap: 3px;
    gap: 3px;
  
  }

    div#scrollVertical{
        height: 100%;
        overflow: none;
    }
    div#strickyVertical{
        position: static;
    }

    #blocGauche section:not(#details_personnels) p{
      text-align: center;
  }

  /*Résau sociaux */
  /*Faire disparître le span */
  .box span{
      display: none;
  }
  .box:hover i{
      -webkit-transform: scale(2);
              transform: scale(2); 
      opacity: 0.3;
  }
  #blocGauche h3{
      letter-spacing: 5px;
  }


}
